import React from "react"
import { graphql } from "gatsby"
import Helmet from 'react-helmet'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Heading from "../../components/heading/heading";
import ContentBlock from "../../components/contentBlock/contentBlock";
import ScrollAnimation from 'react-animate-on-scroll';
import Img from "gatsby-image";

const CStorePage = ({ data }) => {
  return (
    <Layout>
      <SEO title="C-Store" />
      <Helmet bodyAttributes={{class: 'overflow-x-hidden'}} />
      <ScrollAnimation className="product-page-header mt-5" animateIn="fadeInUp" animateOnce={true}>
        <Heading
          title="C-Store"
          copy="The RetailStack Commerce Platform for Convenience Stores makes it easy to operate from the forecourt to the back office. The platform is designed with flexibility in mind with configurations supporting both attended and unattended environments, large inventory set and modern above store capabilities already built in."
          align="center"
        />
        <Img fluid={data.file.childImageSharp.fluid} alt="c-store header" />
      </ScrollAnimation>
      <ContentBlock
        imageCol="right"
        title="Rollback Pricing"
        copy="Fuel price rollbacks are some of the most popular loyalty programs in the country. Now your consumers can see the price per gallon on your dispensed fuels roll back instantly with built in loyalty administration capabilities, payment method specific rollbacks and other options to drive consumer loyalty."
        imageName="cstore-rollback-pricing.png"
      />
      <ContentBlock
        imageCol="left"
        title="Forecourt Controller"
        copy="RetailStack is one of the only forecourt controllers that supports Gilbarco, Wayne and Kraus dispensers all from a single control unit. The controller also provides greater visibility into your pump operations, offering early enterprise outage and break detection. With actionable notifications, you can stay ahead of service issues."
        imageName="cstore-forecourt-controller.png"
      />
      <ContentBlock
        imageCol="right"
        title="Fleet &amp; Payment Card Acceptance"
        copy="Drive more traffic and revenue by accepting more payment types than the competition. The RetailStack platform offers a variety of payment options including credit cards and national fleet card acceptance. The RetailStack platform also offers a built in way to offer private fleet cards to your best customers and configure your own rules for transaction approvals."
        imageName="cstore-EMV.png"
      />
      <ContentBlock
        imageCol="left"
        title="Remote Pump Arming &amp; Pumpside Delivery"
        copy="Allow consumers to activate pumps directly from your mobile app for contactless payment from the forecourt or anywhere in the world. Since the RetailStack Commerce Platform combines your in-store and digital sales, you can drive forecourt sales with innovations like pumpside delivery, which allows your customers to purchase a basket of goods that can be delivered directly to their car while they are fueling."
        imageName="cstore-remote-pump.png"
      />
      <ContentBlock
        imageCol="right"
        title="C-Store Integrations"
        copy="The RetailStack Commerce Platform supports robust store management, configuration and reporting options through Enterprise Back Office. The platform also supports a wide variety of both hardware and software integrations such as price sign and car wash, enterprise inventory management and loyalty integrations."
        imageName="cstore-integrations.png"
        link="/industries/enterprise"
      />
    </Layout>
  )
}

export default CStorePage

export const query = graphql`
  query {
    file(relativePath: { eq: "c-store-header.png" }) {
      childImageSharp {
        fluid(maxWidth: 1170) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`